// import {applyMiddleware, compose, createStore} from 'redux';
import {configureStore, Dispatch} from '@reduxjs/toolkit'
// import thunk from 'redux-thunk';
// import rootReducer from './Redux';
// import appReducer from "../../Redux/app";
// import calcReducer from "../../Redux/calc";
// slices is not use in version 1.3.21
// slices start use at version 1.4.24
// was rewritten on the vehicle to 1.5.27
import appSlice from "../../features/slices/appSlice";
import calcSlice from "../../features/slices/calcSlice";
import orderSlice from "../../features/slices/orderSlice";
import filesSlice from "../../features/slices/filesSlice";


// const storageMiddleware = store => next => action => {
//     next(action);
//     localStorage.setItem("store", JSON.stringify(store.getState()));
// };

const storeToStateMiddleware = (store: { getState: () => {order: object, files: object, calcSlice:object, appSlice: object} }) => (next:Dispatch) => (action: { type: any; }) =>
{
  // first call next
  const nextResult = next(action)

  const state = store.getState()
  if (window.sessionStorage) {
    window.sessionStorage.setItem('vtgCalc', JSON.stringify({
      files: state.files,
    }))
  }
  if (window.localStorage) {
    window.localStorage.setItem('vtgCalc', JSON.stringify({
      order: state.order,
      calcSlice: state.calcSlice,
      appSlice: state.appSlice,
    }))
  }

  return nextResult
}

// const LogMiddleware = store => next => action => {
//     next(action);
//     console.log (store.getState(), 'LogMiddleware');
// };

// old version store
/*const configureStoreOld = preloadedState => {
    const store = createStore(
        rootReducer,
        preloadedState,
        window.__REDUX_DEVTOOLS_EXTENSION__ ?
        compose(
            applyMiddleware(thunk, storageMiddleware),
            window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
        )
        : compose(applyMiddleware(thunk, storageMiddleware))
    );

    return store;
};*/

// export default configureStore;
// const middleware = (getDefaultMiddleware) => getDefaultMiddleware()
//     .concat(storageMiddleware)


const store = configureStore({
  reducer: {
    // @ts-ignore
    // todo: remove ts-ignore when appReducer will be rewrite to slice
    // app: appReducer,

    // calc: calcReducer,

    // now there is only test data
    appSlice: appSlice,

    // this slice цшдд иу гыу штыеуфв ща calcReducer
    calcSlice: calcSlice,

    // data for order page
    order: orderSlice,

    // data for file upload interface
    files: filesSlice

  },
  // middleware: middleware,
  // @ts-ignore
  // todo: remove ts-ignore when appReducer and calcReducer rewrite to slice
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware().concat(storeToStateMiddleware)
  },
  // preloadedState,
  // enhancers: [monitorReducersEnhancer]
})

/*if (process.env.NODE_ENV !== 'production' && module.hot) {
  module.hot.accept('./reducers', () => store.replaceReducer(rootReducer))
}*/

export default store;

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
