// import {UserConfigData} from "../../entites/PartnersConfig";


/**
 * Get partner id from
 */
export const GetPartnerIdFromConfig = ():number => {
    return (window.calcConfig.params.partnerId) ?
      window.calcConfig.params.partnerId
      : 0;
}

/**
 * Calculate url
 * @param defaultValue
 */
export const GetCalcUrlFromConfig = (defaultValue:string): string => {
    return (window.calcConfig.params.urls.calc)
      ? window.calcConfig.params.urls.calc
      : defaultValue;
}

/**
 * Save order url
 * @param defaultValue
 */
export const GetSaveUrlFromConfig = (defaultValue:string): string => {
    return (window.calcConfig.params.urls.save)
      ? window.calcConfig.params.urls.save
      : defaultValue;
}

/**
 * Addons load url
 * @param defaultValue
 */
export const GetAddonsUrlFromConfig = (defaultValue:string): string => {
    return (window.calcConfig.params.urls.addons)
      ? window.calcConfig.params.urls.addons
      : defaultValue;
}

/**
 * Checkout load rl
 * @param defaultValue
 */
export const GetCheckoutUrlFromConfig = (defaultValue:string): string => {
    return (window.calcConfig.params.urls.checkout)
      ? window.calcConfig.params.urls.checkout
      : defaultValue;
}

/**
 * Auth is file upload
 * @param defaultValue
 */
export const GetUploadFileAuthUrlFromConfig = (defaultValue:string): string => {
    return (window.calcConfig.params.urls.uploadFilesAuth)
      ? window.calcConfig.params.urls.uploadFilesAuth
      : defaultValue;
}

/**
 * Restore access code from file upload
 * @param defaultValue
 */
export const GetUploadFileRestoreUrlFromConfig = (defaultValue:string): string => {
    return (window.calcConfig.params.urls.uploadFilesRestore)
      ? window.calcConfig.params.urls.uploadFilesRestore
      : defaultValue;
}

/**
 * Load files list api url
 * @param defaultValue
 */
export const GetUploadFileListUrlFromConfig = (defaultValue:string): string => {
    return (window.calcConfig.params.urls.uploadFilesList)
      ? window.calcConfig.params.urls.uploadFilesList
      : defaultValue;
}

/**
 * Load files list api url
 * @param defaultValue
 */
export const GetUploadFileUrlFromConfig = (defaultValue:string): string => {
    return (window.calcConfig.params.urls.uploadFiles)
      ? window.calcConfig.params.urls.uploadFiles
      : defaultValue;
}

/**
 * Load files list api url
 * @param defaultValue
 */
export const GetUploadFileSaveCommentUrlFromConfig = (defaultValue:string): string => {
    return (window.calcConfig.params.urls.uploadFilesStoreComment)
      ? window.calcConfig.params.urls.uploadFilesStoreComment
      : defaultValue;
}



/**
 * Contact email from params
 * @param defaultValue
 */
export const GetContactEmailFromParams = (defaultValue:string = 'info@ibsvp.co.uk'): string => {
  return (window.calcConfig.params.contactEmail)
      ? window.calcConfig.params.contactEmail
      : defaultValue;
}

/**
 * Contact phone from config params
 * @param defaultValue
 */
export const GetContactPhoneFromParams = (defaultValue:string = '+442079851212'): string => {
  return (window.calcConfig.params.contactPhone)
      ? window.calcConfig.params.contactPhone
      : defaultValue;
}

/**
 * Contact formatted phone from params
 * @param defaultValue
 */
export const GetContactFormattedPhoneFromParams = (defaultValue:string = '+442079851212'): string => {
  return (window.calcConfig.params.formatContactPhone)
      ? window.calcConfig.params.formatContactPhone
      : defaultValue;
}


/**
 * Contact formatted phone from params
 * @param defaultValue
 */
export const GetAvailableFileTypesForUploadDocumentsFromParams = (defaultValue:string[] = []): string[] => {
  return (window.calcConfig.params.availableFileTypes)
      ? window.calcConfig.params.availableFileTypes
      : defaultValue;
}

/**
 * Contact formatted phone from params
 * @param defaultValue
 */
export const GetAvailableFileSizeForUploadDocumentsFromParams = (defaultValue:number = 0): number => {
  return (window.calcConfig.params.maxFileSize)
      ? window.calcConfig.params.maxFileSize
      : defaultValue;
}

/**
 * Available mime types
 * @param defaultValue
 */
export const GetAvailableMimeTypesForUploadDocumentsFromParams = (defaultValue:string[] = []): string[] => {
  return (window.calcConfig.params.availableMimeTypes)
      ? window.calcConfig.params.availableMimeTypes
      : defaultValue;
}



