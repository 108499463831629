import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {FileForUpload} from "../../entities/Files";
import {
  GetAvailableFileSizeForUploadDocumentsFromParams,
  GetAvailableMimeTypesForUploadDocumentsFromParams
} from "../../process/config/GetFromConfig";

type State =
{
  /**
   * Api token
   */
  token: string,

  /**
   * Files list for upload
   */
  filesForUpload: Array<FileForUpload>,
}

let initialState:State =
{
  // todo: remove token
  // token: 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwOi8vMTI3LjAuMC4xOjg4ODAiLCJhdWQiOiJodHRwOi8vMTI3LjAuMC4xOjg4ODAiLCJuYmYiOjE3MDc0MTI5ODcsImlhdCI6MTcwNzQxMzU4Nywib3JkZXJJZCI6MX0.vrsa3doOrF5bmvdZ1gGRBDWmGfcNYDPnzoi1pvxx2bU',
  token: '',
  filesForUpload: [],
}

// load state from session store
// if (window.sessionStorage) {
//   const s = window.sessionStorage.getItem('vtgCalc');
//   if (s) {
//     let data = JSON.parse(s)
//     if (data.order) {
//       initialState = data.order
//     }
//   }
// }

// load state from session store
if (window.sessionStorage) {
  const s = window.sessionStorage.getItem('vtgCalc');
  if (s) {
    let data = JSON.parse(s)
    if (data.files && data.files.token) {
      initialState.token = data.files.token
    }
  }
}

/**
 * Data from order page
 */
const filesSlice = createSlice({
  name: 'files',
  initialState,
  reducers: {

    SetToken (state:State, action:PayloadAction<string>){
      state.token = action.payload
    },

    // clean list
    cleanFileList (state:State){
      state.filesForUpload = []
    },

    // add files list
    addFilesArrayInList (state:State, action:PayloadAction<Array<File>>)
    {
      let newFileList:Array<FileForUpload> = [...state.filesForUpload]

      //
      const availableMimesList = GetAvailableMimeTypesForUploadDocumentsFromParams();

      /**
       * Max file size in bites
       */
      const maxFileSize = GetAvailableFileSizeForUploadDocumentsFromParams();

      action.payload.forEach((f:File) =>
      {
        // skip if file already exists in list
        const foundFile = newFileList.find((sourceFile:FileForUpload) => {
          return sourceFile.file.name === f.name
        })
        if (foundFile) return

        // error in file upload
        let fileError = '';

        // check file is prompt type
        if (availableMimesList.length > 0) {
          const index = availableMimesList.indexOf(f.type)
          if (index === -1) fileError = 'This file is the wrong type';
        }

        // check file size
        if (f.size > maxFileSize) {
          fileError = 'This file is too big to upload';
        }

        // add file in the list
        newFileList.push({
          file: f,
          success: false,
          error: fileError,
          uploadPercent: 0
        })
      })

      //
      state.filesForUpload = newFileList
    },

    removeFileByFileName (state:State, action:PayloadAction<string>) {
      state.filesForUpload = state.filesForUpload.filter((f:FileForUpload) => f.file.name !== action.payload)
    },

    // set file error
    setFileUploadErrorByIndex (state:State, action:PayloadAction<{index: number, error: string}>) {
      const newFileList = [...state.filesForUpload]
      newFileList[action.payload.index].error = action.payload.error
      state.filesForUpload = newFileList
    },

    // set file error by file name
    setFileUploadErrorByFileName (state:State, action:PayloadAction<{fileName: string, error: string}>) {
      const newFileList = [...state.filesForUpload]
      const fileIndex:number|undefined = newFileList.findIndex((f:FileForUpload) => f.file.name === action.payload.fileName)
      if (fileIndex !== undefined) {
        newFileList[fileIndex].error = action.payload.error
        state.filesForUpload = newFileList
      }
    },

    setFileUploadPercentByFileName (state:State, action:PayloadAction<{fileName: string, percent: number}>) {
      const newFileList = [...state.filesForUpload]
      const fileIndex:number|undefined = newFileList.findIndex((f:FileForUpload) => f.file.name === action.payload.fileName)
      if (fileIndex !== undefined) {
        newFileList[fileIndex].uploadPercent = Math.ceil(action.payload.percent)
        state.filesForUpload = newFileList
      }
    },

    // is file uploaded success
    setFileUploadSuccessByIndex (state:State, action:PayloadAction<number>) {
      const newFileList = [...state.filesForUpload]
      newFileList[action.payload].success = true
      state.filesForUpload = newFileList
    },

    // is file uploaded success by file name
    setFileUploadSuccessByFileName (state:State, action:PayloadAction<string>) {
      const newFileList:FileForUpload[] = [...state.filesForUpload]
      const fileIndex:number|undefined = newFileList.findIndex((f:FileForUpload) => f.file.name === action.payload)
      if (fileIndex !== undefined) {
        newFileList[fileIndex].success = true
        state.filesForUpload = newFileList
      }
    }
  }
})

export const {
  SetToken,
  cleanFileList,
  addFilesArrayInList,
  removeFileByFileName,
  setFileUploadErrorByFileName,
  setFileUploadSuccessByFileName,
  setFileUploadPercentByFileName
} = filesSlice.actions

export default filesSlice.reducer

