import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {DeliveryOrder} from "../../entities/DeliveryOrder";

type State =
{
  firstName: string,
  lastName: string,
  middleName: string,

  // sex?: Sex, // not more sex after 1.6.33
  birthday: string,

  phone: string,
  email: string,

  // not more passport details after 1.6.33
  // passport: string,
  // passport_expires?: string,

  visa_start?: string, // visa start date
  visa_end?: string, // visa end sate

  // delivery data
  delivery: DeliveryOrder,
}

let initialState:State =
{
  // load partner id from config
  // partnerId: GetPartnerIdFromConfig()

  firstName: '',
  lastName: '',
  middleName: '',

  // sex: null,
  birthday: '',

  phone: '',
  email: '',

  // passport: '',
  // passport_expires: null,

  // visa_start: null, // visa start date
  // visa_end: null, // visa end sate

  // // delivery data
  delivery: {

  }
  //   id: null,
  //   company: '',
  //   address_first_line: '',
  //   address_second_line: '',
  //   city: '',
  //   post_code: '',
  // },
}

// load state from session store
if (window.localStorage) {
  const s = window.localStorage.getItem('vtgCalc');
  if (s) {
    let data = JSON.parse(s)
    if (data.order) {
      initialState = data.order
    }
  }
}


/**
 * Data from order page
 */
const orderSlice = createSlice({
  name: 'order',
  initialState,
  reducers: {
    updateFirstName(state:State, action:PayloadAction<string>) {
      state.firstName = action.payload
    },
    updateLastName(state:State, action:PayloadAction<string>) {
      state.lastName = action.payload
    },
    updateMiddleName(state:State, action:PayloadAction<string>) {
      state.middleName = action.payload
    },
    // updateSex(state:State, action:PayloadAction<Sex>) {
    //   state.sex = action.payload
    // },
    updateBirthday(state:State, action:PayloadAction<string>) {
      state.birthday = action.payload
    },
    clearDates(state:State) {
      state.visa_start = undefined
      state.visa_end = undefined
    },
    updateStartDate(state:State, action:PayloadAction<string>) {
      state.visa_start = action.payload
    },
    updateEndDate(state:State, action:PayloadAction<string>) {
      state.visa_end = action.payload
    },
    updatePhone(state:State, action:PayloadAction<string>) {
      state.phone = action.payload
    },
    updateEmail(state:State, action:PayloadAction<string>) {
      state.email = action.payload
    },
    // updatePassport(state:State, action:PayloadAction<string>) {
    //   state.passport = action.payload
    // },
    // updatePassportExpires(state:State, action:PayloadAction<string>) {
    //   state.passport_expires = action.payload
    // },

    selectDelivery(state:State, action:PayloadAction<number>) {
      state.delivery.deliveryId = action.payload
    },

    updateDeliveryData(state:State, action:PayloadAction<DeliveryOrder>) {
      state.delivery = {...state.delivery, ...action.payload}
    },
    // updateNameField(state:State, action:PayloadAction<{fieldName:string, newValue:string}>) {
    //   console.log(action.payload.fieldName, action.payload.newValue)
    //   // state[action.payload.fieldName] = action.payload.newValue
    // },

  }
})

export const {
    updateFirstName,
    updateLastName,
    updateMiddleName,
    // updateSex,
    updateBirthday,
    // updateNameField,
    clearDates,
    updateStartDate,
    updateEndDate,
    updatePhone,
    updateEmail,
    // updatePassport,
    // updatePassportExpires,
    selectDelivery,
    updateDeliveryData
} = orderSlice.actions

export default orderSlice.reducer
